import React, { useEffect, useState } from "react";
import "../App.css";

import { useAppDispatch } from "../reducers";

import { getParts } from "../actions/analysisAction";

import VehicleStep from "./VehicleStep";
import PartsStep from "./PartsStep";
import RepairMethodStep from "./RepairMethodStep";
import UserDataStep from "./UserDataStep";
import EndStep from "./EndStep";
import { LanguageProvider, useLanguage } from "./LanguageContext";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { TbaseReducer } from "../types/reducersType";

import Logo from "../images/Logo";

interface RepairMethod {
  [part: string]: string;
}

interface Params {
  self?: string;
  align?: string;
}

const VehicleFormContent: React.FC = () => {
  const location = useLocation();
  const [params, setParams] = useState<Params>({});

  useEffect(() => {
    const parsedParams = queryString.parse(location.search) as Params;
    setParams({
      self: parsedParams.self || "",
      align: parsedParams.align || "",
    });
  }, [location]);

  const dispatch = useAppDispatch();

  const { step } = useSelector((state: TbaseReducer) => state.analysisReducer);

  const [repairMethods, setRepairMethods] = useState<RepairMethod>({});
  const [repairLocation, setRepairLocation] = useState({
    name: "",
    address: "",
    city: "",
    postalCode: "",
  });
  const [userData, setUserData] = useState({
    address: "",
    city: "",
    postalCode: "",
    agree: false,
  });

  const { setLanguage, t } = useLanguage();

  useEffect(() => {
    dispatch(getParts());
  }, [dispatch]);

  const setSelectedRepairMethod = (part: string, method: string) => {
    setRepairMethods({ ...repairMethods, [part]: method });
  };

  return (
    <>
      <div
        className="container"
        style={
          params.align === "top"
            ? {
                marginTop: "0",
                marginBottom: "auto",
              }
            : {}
        }
      >
        <div className="header">
          <div className="export-iframe">
            <button
              onClick={() => {
                const iframe = `<iframe src="https://estimatics.carboncar.fr/" width="600" height="1000" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;

                navigator.clipboard
                  .writeText(iframe)
                  .then(() => {
                    alert("Le code a été copié dans le presse-papier");
                  })
                  .catch((err) => {
                    console.error("Erreur lors de la copie: ", err);
                  });
              }}
            >
              Intégrer la calculette dans une page
            </button>
          </div>
          <div className="language-switcher">
            <button onClick={() => setLanguage("en")}>EN</button>
            <button onClick={() => setLanguage("fr")}>FR</button>
          </div>
        </div>
        <div className="progress-bar">
          <div className={`step ${step === 1 ? "active" : ""}`}>
            {t("vehicle")}
          </div>
          <div className={`step ${step === 2 ? "active" : ""}`}>
            {t("parts")}
          </div>
          <div className={`step ${step === 3 ? "active" : ""}`}>
            {t("repairMethod")}
          </div>
          <div className={`step ${step === 4 ? "active" : ""}`}>
            {t("locate")}
          </div>
          <div className={`step ${step === 5 ? "active" : ""}`}>
            {t("result")}
          </div>
        </div>
        {step === 1 && <VehicleStep />}
        {step === 2 && <PartsStep />}
        {step === 3 && (
          <RepairMethodStep
            repairMethods={repairMethods}
            setSelectedRepairMethod={setSelectedRepairMethod}
          />
        )}
        {step === 4 && (
          <UserDataStep
            repairMethods={repairMethods}
            repairLocation={repairLocation}
            setRepairLocation={setRepairLocation}
            userData={userData}
            setUserData={setUserData}
          />
        )}
        {step === 5 && <EndStep />}
      </div>
      {params.self !== "1" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Logo
            style={{
              padding: 20,
              height: 40,
            }}
          />
        </div>
      ) : null}
    </>
  );
};

const VehicleForm: React.FC = () => (
  <LanguageProvider>
    <VehicleFormContent />
  </LanguageProvider>
);

export default VehicleForm;
