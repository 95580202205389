import { TanalysisInitialState } from "../types/analysis/analysisType";

export const initialState = {
  step: 1,
  brands: [],
  models: [],
  parts: {},
  selectedBrand: "",
  selectedModel: "",
  weight: "",
  emission: "",
  selectedParts: [],
  score: 0,
  total: 0,
  gainPRE: 0,
  gainR: 0,
} satisfies TanalysisInitialState;

export const analysisReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET_BRANDS":
      return { ...state, brands: action.data || [] };
    case "GET_MODELS":
      return { ...state, models: action.data || [] };
    case "GET_PARTS":
      return { ...state, parts: action.data || [] };
    case "GET_RESULT":
      return {
        ...state,
        score: action.data.score,
        total: action.data.total,
        gainPRE: action.data.gainPRE,
        gainR: action.data.gainR,
      };
    case "UPDATE_BRAND":
      return { ...state, selectedBrand: action.data };
    case "UPDATE_MODEL":
      return { ...state, selectedModel: action.data };
    case "UPDATE_WEIGHT":
      return { ...state, weight: action.data };
    case "UPDATE_EMISSION":
      return { ...state, emission: action.data };
    case "UPDATE_PARTS":
      return { ...state, selectedParts: action.data };
    case "BACK_STEP":
      return { ...state, step: state.step - 1 };
    case "NEXT_STEP":
      return { ...state, step: state.step + 1 };
    default:
      return state;
  }
};
