import React, { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";

import { useLanguage } from "./LanguageContext";

import { TbaseReducer } from "../types/reducersType";

import { useAppDispatch } from "../reducers";

import LeafBlack from "../images/leaf/LeafBlack";
import LeafRed from "../images/leaf/LeafRed";
import LeafOrange from "../images/leaf/LeafOrange";
import LeafGreen from "../images/leaf/LeafGreen";

interface RepairMethodStepProps {
  repairMethods: { [part: string]: string };
  setSelectedRepairMethod: (part: string, method: string) => void;
}

const RepairMethodStep: React.FC<RepairMethodStepProps> = ({
  repairMethods,
  setSelectedRepairMethod,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useLanguage();

  const [currentPage, setCurrentPage] = useState<number>(0);

  const { parts, selectedParts } = useSelector(
    (state: TbaseReducer) => state.analysisReducer
  );

  const repairMethodsOptions = [
    {
      value: "E",
      label: t("E"),
      leaf: <LeafRed />,
    },
    {
      value: "S",
      label: t("S"),
      leaf: <LeafOrange />,
    },
    {
      value: "R1",
      label: t("R1"),
      leaf: <LeafGreen />,
    },
    {
      value: "R2",
      label: t("R2"),
      leaf: <LeafGreen />,
    },
    {
      value: "R3",
      label: t("R3"),
      leaf: <LeafGreen />,
    },
    {
      value: "DSP1",
      label: t("DSP1"),
      leaf: <LeafGreen />,
    },
    {
      value: "DSP2",
      label: t("DSP2"),
      leaf: <LeafGreen />,
    },
    {
      value: "DSP3",
      label: t("DSP3"),
      leaf: <LeafGreen />,
    },
  ];

  const itemsPerPage = 1;
  const pageCount = Math.ceil(selectedParts.length / itemsPerPage);
  const paginatedParts = selectedParts.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handleRepairMethodChange =
    (part: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setSelectedRepairMethod(part, e.target.value);
    };

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, pageCount - 1));
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 0));
  };

  return (
    <div className="form">
      <h2>{t("repairMethod")}</h2>
      {paginatedParts.map((part) => (
        <div key={part}>
          <h3>{`${t("part")} ${currentPage + 1}/${selectedParts.length}: ${
            parts[part].name
          }`}</h3>
          {repairMethodsOptions
            .filter((option) => {
              if (parts[part].isP !== true && option.value.includes("P")) {
                return false;
              }
              if (parts[part].isDSP === false && option.value.includes("DSP")) {
                return false;
              }

              return true;
            })
            .map((option) => (
              <label key={option.value} style={{ marginBottom: 10 }}>
                <input
                  type="radio"
                  name={`repairMethod-${part}`}
                  value={option.value}
                  checked={repairMethods[part] === option.value}
                  onChange={handleRepairMethodChange(part)}
                />
                {option.label} {option.leaf}
              </label>
            ))}
        </div>
      ))}
      <div className="navigation-buttons">
        <button
          className="back-button"
          onClick={() =>
            currentPage === 0 ? dispatch({ type: "BACK_STEP" }) : prevPage()
          }
        >
          {currentPage === 0 ? t("back") : t("backPart")}
        </button>
        <button
          className="next-button"
          onClick={() =>
            currentPage === pageCount - 1
              ? dispatch({ type: "NEXT_STEP" })
              : nextPage()
          }
        >
          {currentPage === pageCount - 1 ? t("next") : t("nextPart")}
        </button>
      </div>
    </div>
  );
};

export default RepairMethodStep;
